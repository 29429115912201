/* ==================================================
  * Template: Farmey
  * Version:  1
  * Date:     April 27 2021
===================================================== */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400&display=swap');
@import url('lib/normalize.css');

/*==================================================*/
             /* [Table of contents] */
/*==================================================*/

/*	
    1. RESET.
    2. COLOR PALETTE.
    3. TYPOGRAPHY. 
    4. SPACE AND ALIGMENT. 
    5. BUTTONS. 
    6. PRELOADER. 
    7. SCROLLING TOP.
    8. YOUTUBE POPUP.
    9. MODAL POPUP. 
    10. GALLERY FILTER.
    11. MAGNIFIC POPUP. 
    12. MASONRY GALLERY. 
    13. MEDIA QUERIES. 
*/

/* =====================================
    1. RESET.
===================================== */

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #9dabaf;
  background-color: #FFF;
  letter-spacing: 0px;
}

strong{
  font-weight: bold;
}

ol, ul{ 
  padding: 0; 
  margin: 0;
  list-style: none;
}

iframe{
  width: 100%;
  border: none;
  display: block;
}

img{
  width: 100%;
}

/** Transition **/
a{
    -webkit-transition: all 0.3s ease-in-out 0s; 
    -moz-transition: all 0.3s ease-in-out 0s; 
    -ms-transition: all 0.3s ease-in-out 0s; 
    -o-transition: all 0.3s ease-in-out 0s; 
    transition: all 0.3s ease-in-out 0s; 
}

a:hover{
    color: var(--secundary-color);
}

/**  Shadow **/
.box-shadow{
  -webkit-box-shadow: 0 0 11px 0 rgba(6,22,58,.14);
  -moz-box-shadow: 0 0 11px 0 rgba(6,22,58,.14);
  box-shadow: 0 0 11px 0 rgba(6,22,58,.14);
}

/* =====================================
    2. COLOR PALETTE.
===================================== */
:root {
  --primary-color: #DE7900;
  --secundary-color: #46866C;
  --primary-heading: #050505;
  --paragraph-color: #1f2223;
  --background-color: #F8F8F8;
}

*::-moz-selection {
  background: var(--primary-color);
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: var(--primary-color);
  color: #fff;
  text-shadow: none;
}

::selection {
  background: var(--primary-color);
  color: #fff;
  text-shadow: none;
}

a {
  color: var(--headline-color);
  text-decoration: none;
}

a:hover {
  color: var(--secundary-color);
  text-decoration: none;
}

/* ===================================== 
    3. TYPOGRAPHY. 
===================================== */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: var(--primary-heading);
}

h1 {
  font-size: 45px;
  line-height: 50px;
}

h2 {
  font-size: 35px;
  line-height: 42px;
}

h3 {
  font-size: 25px;
  line-height: 35px;
}

h4 {
  font-size: 20px;
  line-height: 25px;
}

h5 {
  font-size: 20px;
  line-height: 25px;
  font-family: 'Open Sans', sans-serif;
}

h6 {
  font-size: 16px;
  line-height: 18px;
  font-family: 'Open Sans', sans-serif;
}

p {
  margin: 0 0 15px;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  color: var(--paragraph-color);
}

h6 a {
  font-size: 14px;
  text-decoration: underline;
  text-transform: uppercase;
}

li{
  font-family: 'Open Sans', sans-serif;
}

h5.subtitle{
  color: #a3b0b4;
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 0px;
}

/* ===================================== 
    4. SPACE AND ALIGMENT. 
===================================== */
  .mt-3,
  .my-3 {
    margin-top: 1.5rem !important;
  }

  .mt-4,
  .my-4 {
    margin-top: 3rem !important;
  }

  .mt-5,
  .my-5 {
    margin-top: 5rem !important;
  }

  .mb-5,
  .my-5 {
    margin-bottom: 5rem !important;
  }

  .spacing-p-right {
    padding-right: 35px;
  }

  .spacing-p-left {
    padding-left: 35px;
  }

  .spacing-m-buttom {
    margin-bottom: 25px;
  }

  .icon {
    margin-bottom: 15px !important;
  }

  .bg-wrapper {
    background-color: var(--background-color);
    padding: 5rem 0rem;
  }

  section{
    overflow: hidden !important;
  }

/* ===================================== 
    5. BUTTONS. 
===================================== */
.btn-default {
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  padding: 10px 30px;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 1.3333333;
  letter-spacing: 1px;
  font-weight: 600;
  color: #FFF;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out 0s; 
  -moz-transition: all 0.3s ease-in-out 0s; 
  -ms-transition: all 0.3s ease-in-out 0s; 
  -o-transition: all 0.3s ease-in-out 0s; 
  transition: all 0.3s ease-in-out 0s; 
}

.btn-default:hover {
  color: #fff;
  background-color: var(--secundary-color);
  border-color: var(--secundary-color);
}

.btn-default:focus, .btn-default.focus {
  color: #fff;
  background-color: var(--secundary-color);
  border-color: var(--secundary-color);
  box-shadow: none;
  outline: 0;
}

.btn-default.disabled, .btn-default:disabled {
  color: #fff;
  background-color: var(--secundary-color);
  border-color: var(--secundary-color);
}

.btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  color: #fff;
  background-color: var(--secundary-color);
  border-color: var(--secundary-color);
}

.btn-default.disabled,
.btn-default:disabled {
  color: #fff;
  background-color: var(--secundary-color);
  border-color: var(--secundary-color);
  opacity: 1;
}
  
.btn-default-white {
  color: var(--headline-color);
  background-color: #FFF;
  border-color:  #FFF;
}

.btn-outline{
    background-color: transparent;
    border: solid 2px var(--primary-color);
    color: var(--primary-color);
}

.btn-outline-white{
  background-color: transparent; 
  border: solid 1px #FFF;
  color: #FFF;
}


.btn-lg{
  padding: 10px 35px;
  font-size: 18px;
  line-height:30px;
}

.btn-lg:hover{
  background-color: var(--secundary-color); 
  border: solid 1px var(--secundary-color); 
  color: #FFF;
}

/* ===================================== 
    6. PRELOADER. 
===================================== */
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1031;
  background-color: #FFF;
  z-index: 999999;
}

#loader-wrapper .loader {
  position: absolute;
  /*Setting position of element according to the next parent element with position anything other then default*/
  top: 50%;
  /*50% down on the parent element*/
  left: 50%;
  /*50% right on the parent element*/
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /*50% of element width (50px) left, 50% of element height (50px) up*/
  width: 100px;
  /*Element is 100px wide*/
  height: 100px;
  /*Element is 100px tall*/
  animation: spin .6s linear infinite reverse;
  /*
      Animation is using the spin keyframes
      Animation is 0.8 seconds long
      Aniamtion tweening/easing is linear (no acceleration)
      The animation loops forever
      The animation goes the opposite way than defines
      */
}

#loader-wrapper .loader .ball {
  position: absolute;
  /*Same deal with centering the element like with .loader, but the parent of .ball is .loader*/
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 100%;
  /*Height is 100% of the parent not 100% of the screen height (that's 100vh)*/
  -webkit-animation: spin 1s infinite ease-in-out;
  animation: spin 1s infinite ease-in-out;
  /*
      Animation is using the spin keyframes (in the way it is defines)
      Animation is 1 second long
      Animation loops forever
      Animation tweening/easing is ease-in-out, which has symetrical acceleration at the start as at the end
      */
  /*Here I am using the selector `:nth-child(n)` to select certain elements*/
}

#loader-wrapper .loader .ball:after {
  /*Pseudo-class, there is also :before*/
  position: absolute;
  /*Pseudoclasses for use as shapes need to be position absolute*/
  content: '';
  /*And content defined (Add some text into it)*/
  background-color: var(--primary-color);
  /*SCSS variable using the white from line 8*/
  width: 7px;
  /*Width 5 pixels*/
  height: 7px;
  /*Height 5 pixels*/
  border-radius: 100%;
  /*Corners will be rounded into an oval*/
  top: 0px;
  /*Put this element at the top of the parent*/
}

#loader-wrapper .loader .ball:nth-child(1) {
  /*The first .ball in the parent*/
  -webkit-animation-delay: -0s;
  animation-delay: -0s;
}

#loader-wrapper .loader .ball:nth-child(2) {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
  /*Start the animation 0.1s before*/
}

#loader-wrapper .loader .ball:nth-child(3) {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
  /*Start the animation 0.2s before*/
}

#loader-wrapper .loader .ball:nth-child(4) {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

#loader-wrapper .loader .ball:nth-child(5) {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

#loader-wrapper .loader .ball:nth-child(6) {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

#loader-wrapper .loader .ball:nth-child(7) {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

#loader-wrapper .loader .ball:nth-child(8) {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

#loader-wrapper .loader .ball:nth-child(9) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

#loader-wrapper .loader .ball:nth-child(10) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
  /*I am a fan of negative delays*/
}

@-webkit-keyframes spin {

  /*The keyframes for the animation `spin`*/
  0% {
    /*At 0% of the animation whatever elements have these keyframes set as an animation will have these properties*/
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  /*The balls do not change colour because the animation is set to their parent and not themselves*/
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spin {

  /*The keyframes for the animation `spin`*/
  0% {
    /*At 0% of the animation whatever elements have these keyframes set as an animation will have these properties*/
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  /*The balls do not change colour because the animation is set to their parent and not themselves*/
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}



/* =====================================
    7. SCROLLING TOP.
===================================== */
@media only screen and (min-width: 1024px) {
  main p {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.cd-top {
  display: inline-block;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 40px;
  right: 10px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: var(--secundary-color) url(../images/icons/cd-top-arrow.svg) no-repeat center 50%;
  visibility: hidden;
  border-radius: 5px;
  z-index: 4;
}

.cd-top.cd-is-visible,
.cd-top.cd-fade-out,
.no-touch .cd-top:hover {
  -webkit-transition: opacity .3s 0s, visibility 0s 0s;
  -moz-transition: opacity .3s 0s, visibility 0s 0s;
  transition: opacity .3s 0s, visibility 0s 0s;
}

.cd-top.cd-is-visible {
  /* the button becomes visible */
  visibility: visible;
  opacity: 1;
}

.no-touch .cd-top:hover {
  background-color: var(--primary-color);
}

@media only screen and (min-width: 768px) {
  .cd-top {
    right: 20px;
    bottom: 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .cd-top {
    height: 45px;
    width: 45px;
    right: 30px;
    bottom: 30px;
  }
}

/* =====================================
    8. YOUTUBE POPUP.
===================================== */
.popup-youtube{
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  text-align: center; 
}

.popup-youtube .video-image {
  position: relative;
  display: inline-block;
}

.popup-youtube .video-image img {
  width: 100%
}

.popup-youtube .video-image:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  /*background: transparent url(../img/master/play-button.png) center center no-repeat;*/
}

.popup-youtube .video-image img a:link {
  text-decoration: none;
  color: #FFF;
}

.popup-youtube .video-image img a:visited {
  text-decoration: none;
  color: #FFF;
}

.popup-youtube .video-image img a:hover {
  text-decoration: none;
  color: #FFF;
}

.popup-youtube .video-image img a:active {
  text-decoration: none;
  color: #FFF;
}

/* ===================================== 
    9. MODAL POPUP. 
===================================== */
.modal-box {
  text-align: center;
}

.modal-box p {
  margin: 15px 0px 15px 0px;
}

.modal-sm .modal-content {
  background: #FFF;
  border: 1px solid #ddd;
  border: 1px solid rgba(0, 0, 0, .2);
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  padding: 10px 20px 10px 20px;
}

.close {
  color: #000;
}

@media (min-width: 992px) {
  .modal-md {
    width: 600px;
  }
}

/* .btn-popup {
  display: inline-block;
  margin-left: 5rem;
  margin-top: -10px;
} */

.popup-youtube {
  position: relative;
}

.pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  background: var(--secundary-color);
  width: 70px;
  height: 70px;
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  line-height: 73px;
  font-size: 30px;
  transform: translate(-50%, -50%);
  padding-left: 4px;
}

.pulse::before,
.pulse::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
  border: 1px solid var(--secundary-color);
  top: -15px;
  right: -15px;
  left: -15px;
  bottom: -15px;
  animation: pulse 1.5s linear infinite;
}

.pulse::after {
  animation-delay: .5s;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    transform: scale(1.2);
    opacity: 0;
  }
}

/* ===================================== 
    10. GALLERY FILTER.
===================================== */
/* * {
    -webkit-transform-origin: center !important;
    transform-origin: center !important;
  } */

  .filter-container {
    width: 100%;
    margin: 0rem auto 2.5rem;
    text-align: center;
  }

  .filter {
    padding: 0;
    list-style: none;
  }

  .filter li {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    display: inline-block;
    text-transform: uppercase;
    cursor: pointer;
    line-height: 2;
    padding: 5px 25px;
    position: relative;
    color: var(--headline-color);
    font-weight: 600;
    border: solid 2px var(--main-yellow-color);
    border-radius: 10px;
    margin: 0px 5px;
  }

  .filter li.active {
    background-color: var(--secundary-color);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 50rem !important;
    color: #FFF;
  }

  #kehl-grid {
    position: relative;
    text-align: center;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    /*  max-width:1920px; */
  }

  #kehl-grid:after {
    content: '';
    display: block;
    clear: both;
  }

  .grid-box {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: calc(100% / 3);
    margin: 0;
    padding: 5px;
    /*   box-sizing: border-box;
        -webkit-transform: translate3d(1px,0,0);
        transform: translate3d(1px,0,0); */
    clear: both;
  }


  /* #kehl-grid p {
    color: #333;
    z-index: 4;
    font-size: 12px;
    letter-spacing: 3px;
    font-family: 'Playfair Display', serif;
    font-weight: 400;
  } */

  /* clear fix */
  .grid:after {
    content: '';
    display: block;
    clear: both;
  }

  /* ---- .grid-item ---- */
  .grid-sizer,
  .grid-box {
    width: 33.333%;
  }

  .grid-two-col .grid-sizer,
  .grid-two-col .grid-box {
    width: 49%;
  }

  .grid-four-col .grid-sizer,
  .grid-four-col .grid-box {
    width: 25%;
  }

  .grid-box {
    float: left;
    text-align: left;
  }

  .grid-box img {
    display: block;
    width: 100%;
  }

  .grid-box .image-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(256, 256, 256, 0);
    z-index: 1;
    transition: all 300ms linear;
  }

  .grid-box:hover .image-mask {
    background: rgba(256, 256, 256, .8);
  }

  /* .grid-box h3 {
    position: absolute;
    width: 100%;
    bottom: 30%;
    left: 0;
    margin-bottom: -16px;
    line-height: 26px;
    text-align: center;
    z-index: 4;
    opacity: 0;
    transition: all 300ms linear;
  }

  .grid-box:hover h3 {
    bottom: 50%;
    opacity: 1;
  }

  .grid-box p {
    position: absolute;
    top: 15px;
    left: 25px;
    opacity: 0;
    font-style: italic;
    line-height: 18px;
    transition: all 300ms linear;
  }

  .grid-box:hover p {
    top: 60px;
    left: 25px;
    opacity: 0;
  } */

  
/* ===================================== 
    11. MAGNIFIC POPUP. 
===================================== */
.magnific-img {
  width: 100%;
  height: 100%;
}

.magnific-img img {
  width: 100%;
  height: 100%;
  border-radius: 0px;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.mfp-arrow-left:before {
  border-right: none !important;
}

.mfp-arrow-right:before {
  border-left: none !important;
}

button.mfp-arrow,
.mfp-counter {
  opacity: 0 !important;
  transition: opacity 200ms ease-in, opacity 2000ms ease-out;

}

.mfp-container:hover button.mfp-arrow,
.mfp-container:hover .mfp-counter {
  opacity: 1 !important;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.9;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

/* .mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
} */
/* .mfp-auto-cursor .mfp-content {
  cursor: auto;
} */
.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: -10px;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: 'Poppins', sans-serif;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: -10px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  text-align: center; 
  color: var(--base-color);
  font-size: 16px;
  line-height: 18px;
  white-space: nowrap;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  margin-top: 30px;
  
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {

  /**
         * Remove all paddings around the image on small screen
         */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.magnific-img .image-popup-vertical-fit img a:link {
  text-decoration: none;
  border: solid 2px #000;
}

.magnific-img .image-popup-vertical-fit img a:visited {
  text-decoration: none;
  border: solid 2px #000;
}

.magnific-img .image-popup-vertical-fit img a:hover {
  text-decoration: none;
  border: solid 2px #000;
}

.magnific-img .image-popup-vertical-fit img a:active {
  text-decoration: none;
  color: #000;
  border: solid 2px #000;
}

.mfp-figure button.mfp-close {
  cursor: pointer;
}

/* .popup-gallery a {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}
.mfp-figure figure {
  cursor: -webkit-zoom-out;
  cursor: -moz-zoom-out;
  cursor: zoom-out;
} */
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  z-index: 1;
  right: -10px !important;
  font-size: 40px;
  cursor: pointer !important;
  color: var(--base-color)
}

.mfp-image-holder .mfp-close:hover,
.mfp-iframe-holder .mfp-close:hover {
  color: white;
  border-color: white;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  background: url(../images/icons/left-arrow.png) no-Repeat top left !important;
  width: 110px;
  height: 110px;
  left: 30px;
}

.mfp-arrow-right {
  background: url(../images/icons/next.png) no-Repeat top left !important;
  width: 110px;
  height: 110px;
  right: 30px;
}

/* .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px;
  } */
/* .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F;
  } */
/* .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px;
  }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F;
  } */


/* ===================================== 
    12. MASONRY GALLERY. 
===================================== */
/* ---- isotope ---- */
    /* .grid {
      background: #DDD;
    } */
    /* clear fix */
    .grid:after {
      content: '';
      display: block;
      clear: both;
    }

    /* ---- .grid-item ---- */
    .grid-sizer,
    .grid-item {
      width: 33.333%;

    }

    .grid-item {
      float: left;
    }

    .grid-item img {
      display: block;
      max-width: 100%;
    }

    @media (max-width:992px) {

      .grid-sizer,
      .grid-item {
        width: 50%;
      }
    }  

/* ===================================== 
    13. MEDIA QUERIES. 
===================================== */
@media (max-width:1200px) {
  .container {
    max-width: 95%;
  }

  .spacing-lg {
    margin-top: 50px;
  }

}

@media (max-width:992px) {
  h1 {
    font-size: 40px;
    line-height: 45px;
  }

  h2 {
    font-size: 30px;
    line-height: 35px;
  }

  h3 {
    font-size: 25px;
    line-height: 30px;
  }

  h4 {
    font-size: 20px;
    line-height: 25px;
  }

  h5 {
    font-size: 16px;
    line-height: 24px;
  }

  h6 {
    font-size: 14px;
    line-height: 18px;
  }

  .spacing-m-center {
    margin: 40px 0px;
  }

  .spacing-md {
    margin-top: 60px;
  }

  .ca-left-side {
    text-align: center;
  }

  .ca-right-side .btn-primary {
    float: none;
    margin-top: 15px;
  }

  .flex-parent {
    display: flex;
  }

  .jc-center {
    justify-content: center;
  }

}

@media (max-width:991px) {
  h1 {
    font-size: 40px;
    line-height: 45px;
  }

  h2 {
      font-size: 30px;
      line-height: 35px;
  }

  h3 {
      font-size: 25px;
      line-height: 30px;
  }

  h4 {
      font-size: 20px;
      line-height: 25px;
  }

  h5 {
      font-size: 18px;
      line-height: 24px;
  }

  h6 {
      font-size: 14px;
      line-height: 18px;
  }

  .order-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-md-12 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-last {
    -ms-flex-order: 13;
    order: 13;
  }

}

@media (max-width:767px) {

  .mt-5,
  .my-5 {
    margin-top: 4rem !important;
  }

  .mb-5,
  .my-5 {
    margin-bottom: 4rem !important;
  }

}

@media (max-width:576px) {
  .spacing-sm{
    margin-top: 40px;
  }
  
}

@media (max-width:480px) {
  .mt-5,
  .my-5 {
    margin-top: 3rem !important;
  }

  .mb-5,
  .my-5 {
    margin-bottom: 3rem !important;
  }

}

@media (max-width:380px) {}

