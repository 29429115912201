/* =====================================
    MEDIA QUERIES.
===================================== */
@media (max-width:1600px) {
  .why-us-info {
    width: 80%;
    padding-left: 40px;
  }

  .services-layer {
    padding: 30px 0px;
  }

  .left-wrapper-content {
    width: 80%;
  }

}


@media (max-width:1300px) {
  .home-about-img {
    margin-top: 0px;
  }

  .home-about {
    margin: 0px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .left-wrapper-content {
    width: 90%;
  }

  .ws-inner {
    margin: 0px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 90%;
    padding-left: 50px;
}

}

@media (max-width:1200px) {
  .container {
    max-width: 95%;
  }

  .hero-slider .flickity-page-dots {
    display: none;
  }

  .why-us-info {
    width: 85%;
    padding-left: 30px;
  }

  .news-caption {
    padding: 35px 20px;
    position: absolute;
    top: auto;
    bottom: -50px;
    width: 92%;
  }

}

@media (max-width:991px) {

  .pages-hero {
    height: 100px;
  }

  .grid-four-col .grid-sizer,
  .grid-four-col .grid-box {
    width: 50%;
  }

  .social-media {
    display: none;
  }

  .home-about {
    margin: 0px;
    position: relative;
    top: auto;
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    padding-right: 0px;
  }

  .why-us-img-bg {
    height: 400px;
  }

  .why-us-info {
    width: 100%;
    margin: 0px;
    position: relative;
    top: auto;
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    padding: 50px 30px;
  }

  .contact-info-right {
    display: none;
  }

  .contact-form-layer {
    background-color: #FFF;
    padding: 50px;
    max-width: 650px;
    margin-top: -300px;
    position: relative;
    z-index: 1;
  }

  .testimonials-layer {
    padding: 60px 0px 55px;
  }

  .testimonials-carousel .owl-dots {
    display: none;
  }

  .testimonial-box p {
    font-size: 16px;
    line-height: 27px;
  }

  .user-avatar {
    width: 110px;
  }

  .testimonial-box .fas {
    position: absolute;
    top: 13%;
    font-size: 120px;
  }

  p.profession {
    margin-top: 5px;
  }

  footer {
    padding: 75px 0px 40px;
  }

  hr.footer {
    margin: 50px 0px 30px;
  }

  .bottom-footer {
    position: absolute;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 10px 30px;
  }

  .bottom-footer {
    position: relative;
    bottom: 10px;
    left: 0%;
    transform: translate(0%, 0%);
    text-align: center;
    padding: 10px 30px;
  }

  .call-to-action {
    height: 100%;
    padding: 2rem 1.8rem 1.8rem;
  }

  .call-left,
  .call-right {
    float: none;
    padding: 0rem;
    text-align: center;
  }

  .call-right {
    margin-top: 10px;
  }

  .call-button {
    margin-top: 15px;
  }

  .right-contact-badge {
    height: auto;
  }

  .slider-caption p {
    width: 70%;
  }

  .about-info-layer {
    padding-right: 0px;
  }

  .services-layer-alt .section-title {
    width: 100%;
    margin-bottom: 20px;
  }

  .right-title {
    margin-top: 10px;
  }

  .left-wrapper-layer {
    height: 500px;
    background: rgba(255, 255, 255, 0.85);
  }

  .call-action-wrapper p {
    margin: 15px 0px;
  }

  .ci-right {
    display: none;
  }

  .why-us-content {
    margin: 0px;
    position: relative;
    top: auto;
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    padding-left: 0px;
  }

  .ci-left {
    margin-top: 0px;
    margin-right: 0px;
  }

  .aside-right {
    padding-left: 0px;
  }

  .product-featur-img {
    outline: 5px solid var(--primary-color);
    outline-offset: -15px;
  }

  .ws-bg-img {
    height: 400px;
}

  .ws-content {
    background-color: var(--primary-heading);
    height: auto;
    padding: 70px 30px;
    margin-top: 0px;
    margin-left: 0px;
    z-index: 1;
    position: relative;
}

  .ws-inner {
    margin: 0px;
    position: relative;
    top: auto;
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    width: 100%;
    padding-left: 0px;
}

.counter-wrapper-alt {
  /*background-image: url("../images/commons/counter-bg.jpg");*/
  padding: 50px 0px;
  margin-top: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: -1;
}

.why-us-info-alt {
  margin: 0px;
  position: relative;
  top: auto;
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  padding-right: 0px;
}

.why-us-about .image-badget {
  background-color: var(--primary-color);
  padding: 30px;
  max-width: 300px;
  position: absolute;
  bottom: 15px;
  left: 15px;
  margin-left: 0px;
}

.small-text{
  margin-top: 15px;
}

.why-us-info-alt h2 {
  font-size: 30px;
  line-height: 35px;
}

.feature-side-top{
  margin-top: 20px;
}

.product-card-caption {
  height: 100%;
  background-color: #FFF;
  padding: 50px 30px;
}


.inner-pc {
  margin: 0px;
  position: relative;
  top: auto;
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  padding-right: 0px;
}

}

@media (max-width:767px) {

  .slider-caption h1 {
    font-size: 35px;
    line-height: 45px;
  }

  .slider-caption .subtitle {
    font-size: 14px;
  }

  .slider-caption p {
    font-size: 16px;
    line-height: 28px;
    margin: 15px 0px;
  }

  .slider-caption .subtitle {
    font-size: 16px;
    letter-spacing: 5px;
  }

  .section-title {
    width: 80%;
  }

  .home-slider {
    width: 100%;
    height: 100vh;
  }

  .hero-slider {
    width: 100%;
    height: 100vh;
  }

  .error-page-content {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    transform: translate(0%, 0%);
    text-align: center;
    color: #FFF;
    padding: 0rem 5rem;
  }

  .error-page-content {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    transform: translate(0%, 0%);
    text-align: center;
    color: #FFF;
    padding: 0rem 5rem;
  }

  .pages-title h1 {
    font-size: 30px;
    line-height: 35px;
  }

  .contact-form-layer {
    max-width: 550px;
    padding: 50px 30px;
    margin-top: -300px;
    position: relative;
    z-index: 1;
  }

  .contact-form-layer .section-title {
    margin: auto auto 50px;
  }

  .bg-thumb .date-layer {
    bottom: auto;
    top: 0px;
    right: 0px;
  }

  .testimonials-layer .section-title {
    margin: auto auto 20px auto;
  }

  .testimonials-layer .section-title h2 {
    font-size: 30px !important;
  }

  .news-carousel .owl-carousel .item .news-thumbnail {
    margin: 0px;
  }

  .support-banner {
    padding: 50px 0px;
  }

  .support-layer {
    margin-top: 50px;
  }

  .support-banner p {
    width: 80%;
  }

  .blog-caption {
    background-color: #FFF;
    margin-top: 20px;
    z-index: 1;
    position: relative;
    padding: 20px 0px;
    width: 100%;
  }

}

@media (max-width:576px) {
  .section-title {
    width: 90%;
  }

  .grid-four-col .grid-sizer,
  .grid-four-col .grid-box {
    width: 100%;
  }

  .error-page-content {
    position: absolute;
    padding: 0rem 3rem;
  }

  .error-page-content h1 {
    font-size: 6rem;
  }

  .home-about h2 {
    color: #a3b0b4;
    opacity: 1;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 18px;
  }

  .h-counter {
    margin-top: 30px;
  }

  .home-about h3 {
    font-size: 25px;
    line-height: 35px;
    margin-top: 0px;
  }

  .contact-badge {
    margin-top: 50px;
  }

  .left-contact-badge {
    height: auto;
    padding: 0px;
  }

  .pages-hero-contact {
    height: 350px;
  }

  .contact-title,
  .pages-title {
    bottom: 50px;
  }

  .testimonial-box {
    width: 90%;
  }

  .testimonial-box p {
    font-size: 14px;
    line-height: 22px;
  }

  .user-avatar {
    width: 80px;
  }

  .testimonial-box h3 {
    font-size: 20px;
  }

  .social-media {
    bottom: 90px;
  }

  .slider-caption p {
    width: 85%;
  }

  .hb-box {
    display: block !important;
  }

  .hb-box {
    margin-top: 30px;
  }

  .team-carousel .owl-carousel .item .team-portrait {
    margin: 0px;
  }

  .section-title h2 {
    font-size: 40px;
  }

  .news-caption {
    bottom: -80px;
  }

  .support-banner p {
    width: 90%;
  }

  .replay-layer {
    padding-left: 20px;
  }

  .post-title h1 {
    font-size: 30px;
    line-height: 35px;
    width: 100%;
    padding-right: 30px;
  }

  .comment-box p {
    font-size: 14px;
    line-height: 25px;
  }

  .why-us-about .image-badget {
    background-color: var(--primary-color);
    padding: 30px;
    max-width: 100%;
    position: relative;
    bottom: auto;
    left: 0px;

  }

  .contact-box {
    width: 100%;
    height: 550px;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 50%;
    right: 0;
    padding: 40px;
  }

  .contact-parallax {
    /*background-image: url("../images/commons/farmey-8.jpg");*/
    height: 550px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

}

@media (max-width:480px) {
  .section-title {
    width: 95%;
  }

  .image-badget {
    background-color: var(--primary-color);
    padding: 30px;
    max-width: 100%;
    position: relative;
    bottom: 0px;
    left: 0px;
    margin-top: 50px;
  }

  .photo-contact {
    display: none;
  }

  .product-description {
    background-color: #FFF;
    position: relative;
    -webkit-box-shadow: 0 0 11px 0 rgba(6, 22, 58, .14);
    -moz-box-shadow: 0 0 11px 0 rgba(6, 22, 58, .14);
    box-shadow: 0 0 11px 0 rgba(6, 22, 58, .14);
    padding: 40px 30px;
    margin: 0px 0px 40px;
    text-align: center;
  }

  .testimonial-box {
    width: 95%;
  }

  .slider-caption p {
    width: 90%;
  }

  .service-box p {
    font-size: 14px;
    line-height: 24px;
  }

  .left-col {
    padding-right: 0px;
  }

  .left-wrapper-content {
    width: 100%;
    padding: 0px 30px;
  }

  .shadow-box {
    max-width: 700px;
    margin: -250px auto auto;
    padding: 50px 20px;
  }

  .comment-box .media {
    display: block !important;
  }

  .comment-box .media .mr-3 {
    margin-bottom: 20px;
  }

  .left-title h2 {
    width: 90%;
  }

  .accordion a {
    font-size: 15px;
  }

  .video-iframe {
    width: 100%;
    height: 400px;
  }

  .fp-caption {
    position: absolute;
    bottom: 20px;
    left: 15px;
    width: 90%;
  }

  .feature-side-top .fp-caption {
    position: absolute;
    bottom: 10px;
    left: 15px;
    width: 80%;
  }

  .feature-side-bottom .fp-caption {
    position: absolute;
    bottom: 10px;
    left: 15px;
    width: 80%;
  }
    
  .feature-side-top:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, #FFF, #FFF);
    opacity: 0.6;
  }

  .feature-side-bottom:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, #FFF, #FFF);
    opacity: 0.6;
  }

}