.navbar {
  position: absolute;
  left: 0;
  top: 25px;
  padding: 0;
  width: 100%;
  z-index: 2;
}

.navbar .container {
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar .navbar-brand {
  width: 200px;
}

.navbar .navbar-brand img {
  width: 100%;
}

.navbar .navbar-toggler {
  position: relative;
  height: 60px;
  width: 60px;
  border: none;
  cursor: pointer;
  outline: none;
}

.navbar .navbar-toggler .menu-icon-bar {
  position: absolute;
  left: 15px;
  right: 15px;
  height: 2px;
  background-color: #FFF;
  opacity: 0;
  -webkit-transform: translateY(-1px);
  -ms-transform: translateY(-1px);
  transform: translateY(-1px);
  transition: all 0.3s ease-in;
}

.navbar .navbar-toggler .menu-icon-bar:first-child {
  opacity: 1;
  -webkit-transform: translateY(-1px) rotate(45deg);
  -ms-sform: translateY(-1px) rotate(45deg);
  transform: translateY(-1px) rotate(45deg);
}

.navbar .navbar-toggler .menu-icon-bar:last-child {
  opacity: 1;
  -webkit-transform: translateY(-1px) rotate(135deg);
  -ms-sform: translateY(-1px) rotate(135deg);
  transform: translateY(-1px) rotate(135deg);
}

.navbar .navbar-toggler.collapsed .menu-icon-bar {
  opacity: 1;
}

.navbar .navbar-toggler.collapsed .menu-icon-bar:first-child {
  -webkit-transform: translateY(-7px) rotate(0);
  -ms-sform: translateY(-7px) rotate(0);
  transform: translateY(-7px) rotate(0);
}

.navbar .navbar-toggler.collapsed .menu-icon-bar:last-child {
  -webkit-transform: translateY(5px) rotate(0);
  -ms-sform: translateY(5px) rotate(0);
  transform: translateY(5px) rotate(0);
}

.navbar-dark .navbar-nav .nav-link {
  position: relative;
  color: #FFF;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 10px;
}

.navbar-dark .navbar-nav .nav-link:focus {
  color: var(--primary-color);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: var(--primary-color);
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: var(--primary-color);
}

.navbar .dropdown-menu {
  padding: 0;
  background-color: #FFF;
  transition: opacity .15s ease-in-out;
}

.navbar .dropdown-menu .dropdown-item {
  position: relative;
  padding: 10px 20px;
  color: var(--headline-color);
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  border-bottom: 1px solid rgb(221, 221, 221, 0.7);
  transition: color 0.2s ease-in;
}

.dropdown-menu {
  border: none;
  border-radius: 0rem;
  -webkit-box-shadow: 0 0 11px 0 rgba(6,22,58,.14);
  -moz-box-shadow: 0 0 11px 0 rgba(6,22,58,.14);
  box-shadow: 0 0 11px 0 rgba(6,22,58,.14);
}

.navbar .dropdown-menu .dropdown-item:focus {
  background: transparent;
}

.navbar .dropdown-menu .dropdown-item:last-child {
  border-bottom: none;
}

.navbar .dropdown-menu .dropdown-item:hover {
  background: transparent;
  color: var(--primary-color);
}

.navbar .dropdown-menu .dropdown-item::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 5px;
  background-color: transparent; /* navbar dropdowon border left */
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.navbar .dropdown-menu .dropdown-item:hover::before {
  opacity: 1;
}

.navbar.fixed-top {
  position: fixed;
  top: 0;
  -webkit-animation: fade .50s;
  animation: fade .50s;
  background-color: var(--secundary-color);
  -webkit-box-shadow: 0 4px 4px -6px #333;
  -moz-box-shadow: 0 4px 4px -6px #333;
  box-shadow: 0 4px 4px -6px #333;
}


.navbar.fixed-top.navbar-dark .navbar-nav .nav-link {
  font-size: 14px;
}

/* .navbar.fixed-top.navbar-dark .navbar-brand {
    margin-top: -5px;
  }
   */

.navbar.fixed-top.navbar-dark .container {
  background-color: transparent;
}


.navbar.fixed-top.navbar-dark .navbar-nav .nav-link.active {
  color: #c0ca33;
}

.navbar.fixed-top.navbar-dark .navbar-nav .nav-link::after {
  background-color: #c0ca33;
}

.content {
  padding: 120px 0;
}

.dropdown-toggle::after {
  transition: transform 0.15s linear;
}

.show.dropdown .dropdown-toggle::after {
  transform: translateY(3px);
}

.dropdown-menu {
  margin-top: 0;
}

@media screen and (max-width: 991px) {
  .navbar-nav {
    padding: 20px;
    background-color: #FFF;
  }

  .navbar .container {
    padding: 0px;
    border-radius: 0px;
  }

  .navbar-collapse .navbar-nav .nav-link {
    color: var(--headline-color);
    border-bottom: 1px solid rgb(221, 221, 221, 0.7);
    line-height: 2;
    margin-left: 0px;
    font-size: 13px;
  }

  .navbar-collapse .navbar-nav .last-link-item {
    border-bottom: none;
  }

  .navbar-collapse .navbar-nav .contact-link {
    font-size: 13px;
    padding: 0px;
    border-bottom: none;
  }

  .navbar.fixed-top .navbar-nav {
    background: transparent;
  }

  .navbar.fixed-top .navbar-nav .nav-link{
    border-bottom: 1px solid rgb(221, 221, 221, 0.7);
    line-height: 2;
    margin-left: 0px;
    font-size: 13px !important;
    color: #FFF;
  }

  .navbar.fixed-top .navbar-collapse .navbar-nav .last-link-item {
    border-bottom: none;
  }

  .navbar .dropdown-menu {
    padding: 0;
    background-color: #FFF;
    border: none;
  }

  .navbar .dropdown-menu .dropdown-item {
    position: relative;
    padding: 10px 20px;
    color: var(--primary-heading);
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    border-bottom: 1px solid rgb(221, 221, 221, 0.7);
    transition: color 0.2s ease-in;
   
  }

  .navbar.fixed-top .dropdown-menu .dropdown-item {
    background-color: var(--secundary-color);
    color: #FFF;
  }

  .navbar.fixed-top .navbar-dark .navbar-nav .nav-link:focus,
  .navbar.fixed-top .navbar-dark .navbar-nav .nav-link:hover {
    color: #FFF;
  }

  .navbar .navbar-brand {
    width: 150px;
    margin-left: 10px;
  }

  .dropdown-menu {
    border: none;
    border-radius: 0rem;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }  


}

@media screen and (min-width: 992px) {
  .banner {
    padding: 0 150px;
  }

  .banner h1 {
    font-size: 5rem;
  }

  .banner p {
    font-size: 2rem;
  }

  .navbar-dark .navbar-nav .nav-link {
    padding: 23px 15px;
  }

  .navbar-dark .navbar-nav .nav-link:hover::after {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }

  .dropdown-menu {
    min-width: 200px;
    -webkit-animation: dropdown-animation 0.4s;
    animation: dropdown-animation 0.4s;
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
  }

}


@media (max-width:767px) {
  .navbar .navbar-brand {
    margin-top: 0px;
  }

  .navbar .navbar-brand {
    width: 160px;
  }

  .navbar .container {
    background-color: transparent;
  }

  .navbar {
    background-color: transparent;

  }

}


@-webkit-keyframes navbar-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes navbar-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes dropdown-animation {
  0% {
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
  }

  75% {
    -webkit-transform: scaleY(1.1);
    -ms-transform: scaleY(1.1);
    transform: scaleY(1.1);
  }

  100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes dropdown-animation {
  0% {
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
  }

  75% {
    -webkit-transform: scaleY(1.1);
    -ms-transform: scaleY(1.1);
    transform: scaleY(1.1);
  }

  100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
}